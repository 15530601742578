import {ChangeDetectorRef, Directive} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {tap} from 'rxjs';
import {MatIcon} from '@angular/material/icon';
import {Language} from '../../../../projects/admin-query/src/app/core/api/generated/msa-admin-query';

@Directive({
  selector: '[appLanguageIcon]',
  standalone: true
})
export class LanguageIconDirective {
  private originalSvgName: string;

  constructor(
    private matIcon: MatIcon,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    this.originalSvgName = this.matIcon.svgIcon;

    this.updateIcon(this.translateService.currentLang ?? Language.De);

    this.translateService.onLangChange
      .pipe(
        tap(langChange => {
          this.updateIcon(langChange.lang);
          this.changeDetectorRef.detectChanges();
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  private updateIcon(language: string) {
    this.matIcon.svgIcon = `${language.toLowerCase()}-${this.originalSvgName}`;
  }
}
